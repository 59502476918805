<template>
  <div
    class="
    flex
    items-center
    justify-center
    w-screen
    h-screen
    bg-gradient-to-r
    from-secondary
    to-primary
  "
  >
    <div class="w-3/4 py-20 bg-background rounded-md shadow-lg">
      <div class="flex flex-col items-center">
        <h1 class="font-bold text-blue-600 text-6xl md:text-9xl">404</h1>

        <h6 class="mb-2 text-2xl font-bold text-center text-gray-800 md:text-3xl">
          <span class="text-red-500">Oops!</span> Aradığın sayfa bulunamadı
        </h6>

        <p class="mb-8 text-center w-full md:text-lg">
          Lütfen tekrardan link isteyiniz.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
};
</script>

<style scoped>

</style>
