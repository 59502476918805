<template>
  <div>
    <button
      @click="toggleModal">
      <svg fill="#5D78A1" width="24" height="24">
        <path :d="icons.mdiNoteEditOutline"></path>
      </svg>
    </button>
    <div v-if="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none
    focus:outline-none justify-center items-center flex">
      <div class="bg-white w-4/5 xl:w-2/3 ">
        <component :is="currentComponent"
        :data="data" @closeModal="closeToggle" />
      </div>
    </div>
    <div v-if="showModal" class="opacity-25 fixed inset-0 z-40 bg-[#000000]"></div>
  </div>
</template>

<script>
import { mdiNoteEditOutline } from '@mdi/js';
import EditSwift from '@/components/admin/bank-informations/modals/edit-bank-info/EditSwift.vue';
import EditCryptoCurrency
  from '@/components/admin/bank-informations/modals/edit-bank-info/EditCryptoCurrency.vue';
import EditBankInformation
  from '@/components/admin/bank-informations/modals/edit-bank-info/EditBankInformation.vue';
import { useI18n } from 'vue-i18n';

export default {
  name: 'IconModal',
  props: ['data', 'category'],
  components: {
    EditSwift,
    EditCryptoCurrency,
    EditBankInformation,
  },
  data() {
    return {
      showModal: false,
      icons: {
        mdiNoteEditOutline,
      },
    };
  },
  methods: {
    toggleModal() {
      this.showModal = !this.showModal;
    },
    closeToggle(value) {
      this.showModal = value;
    },
  },
  setup(props) {
    const { t } = useI18n({});
    let currentComponent = '';
    if (props.category === t('common.tabNames.swift')) {
      currentComponent = 'EditSwift';
    } else if (props.category === t('common.tabNames.cryptoCurrencies')) {
      currentComponent = 'EditCryptoCurrency';
    } else {
      currentComponent = 'EditBankInformation';
    }
    return {
      currentComponent,
    };
  },
};
</script>
