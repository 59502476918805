<script setup>
const props = defineProps({
  color: {
    type: String,
    default: '#FFFFFF',
    required: true,
  },
  text: {
    type: String,
    default: '',
    required: true,
  },
});
</script>

<template>
  <div class=" text-sm border py-1 px-1.5 my-auto flex rounded-lg"
       :style="{ color: props.color}">
    <slot v-if="hasFlag" name="flag"></slot>
    <span class="my-auto ml-1">{{ props.text }}</span>
  </div>
</template>

<script>
export default {
  name: 'ChipComponent',
  computed: {
    hasFlag() {
      return !!this.$slots.flag;
    },
  },
};
</script>

<style scoped>

</style>
