<script setup>
defineProps({
  buttonText: {
    type: String,
    required: true,
  },
});

</script>

<template>
  <div>
    <button class="w-fit px-5 py-2 mt-2 bg-secondary text-primary font-medium
      text-sm rounded-lg transition duration-150 ease-in-out">{{ buttonText }}
    </button>
  </div>
</template>

<script>
export default {
  name: 'ButtonComponent',
};
</script>
