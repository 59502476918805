import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '@/views/Dashboard.vue';
import Admin from '@/views/Admin.vue';
import Login from '@/views/Login.vue';
import Error from '@/views/Error.vue';
import useLoginStore from '@/store/login';

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/',
    name: 'admin',
    component: Admin,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/error',
    name: 'error',
    component: Error,
  },
  {
    path: '/:token',
    name: 'dashboard',
    component: Dashboard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loginStore = useLoginStore();

  if (to.meta.requiresAuth) {
    if (loginStore.isLoggedIn) {
      next();
    } else {
      next({ name: 'login' });
    }
  } else next();
});

export default router;
