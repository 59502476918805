<script setup>
import { mdiContentCopy } from '@mdi/js';
import { reactive } from 'vue';
// import the library
import { createToast } from 'mosha-vue-toastify';
// import the styling for the toast
import 'mosha-vue-toastify/dist/style.css';

const props = defineProps({
  internetBanking: {
    required: true,
  },
});

const state = reactive({
  icons: {
    mdiContentCopy,
  },
});

function copyUrl(value) {
  navigator.clipboard.writeText(value).then(() => {
    createToast(
      {
        title: 'Başarılı',
        description: 'Başarıyla kopyalandı!',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  });
}
</script>

<template>
  <div :id="internetBanking.id">
    <div class="flex w-full h-full flex-row items-center rounded-lg bg-secondary">
      <div class="flex-shrink-0">
        <img
          class="w-16 h-16 ml-2 rounded-full"
          :src="`https://payments-gate.com${internetBanking.image_url}`"
          alt="Neil image"
        />
      </div>
      <div class="flex w-full flex-col ml-4">
        <div class="mt-2 text-primary text-lg font-medium">{{ internetBanking.bank_name }}</div>
        <div class="text-link flex flex-row">
<span class="mr-2">
          <span class="font-medium">Hesap adı:</span>
          {{ internetBanking.account_name }}
</span>
<button
              v-if="props.internetBanking.account_name !== '**********'"
              class="flex ml-auto my-auto mr-4"
              @click="copyUrl(internetBanking.account_name)"
          >
            <svg fill="#5D78A1" width="24" height="24">
              <path :d="state.icons.mdiContentCopy"></path>
            </svg>
          </button>
        </div>
        <div class="text-link">
          <span class="font-medium">Şube kodu:</span>
          {{ internetBanking.branch_code }}
        </div>
        <div class="text-link">
          <span class="font-medium">Hesap numarası:</span>
          {{ internetBanking.account_number }}
        </div>
        <div class="flex">
          <span class="w-full my-auto flex text-link">
            <div class="flex flex-col">
              <span class="font-medium">Iban:</span>
              <span ref="mylink" class="break-all">{{ internetBanking.iban }} </span>
            </div>
            <button
              v-if="internetBanking.iban !== '**********'"
              class="flex ml-auto my-auto mr-4"
              @click="copyUrl(internetBanking.iban)"
            >
              <svg fill="#5D78A1" width="24" height="24">
                <path :d="state.icons.mdiContentCopy"></path>
              </svg>
            </button>
          </span>
        </div>
        <div class="text-link flex flex-row">
          <span class="font-medium">Döviz Birimi:</span>
          <span>
            {{ internetBanking.currency }}
          </span>
        </div>
        <div class="text-link flex flex-row mb-2">
          <span class="font-medium">Açıklama:</span>
          <span>
            {{ internetBanking.comment }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InformationComponent',
};
</script>
