<template>
  <div class="rounded-lg bg-secondary/50 p-4 w-full mx-auto">
    <div class="animate-pulse flex space-x-4">
      <div class="rounded-full bg-primary/50 h-10 w-10"></div>
      <div class="flex-1 space-y-6 py-1">
        <div class="h-2 bg-primary/50 rounded"></div>
        <div class="space-y-3">
          <div class="grid grid-cols-3 gap-4">
            <div class="h-2 bg-primary/50 rounded col-span-2"></div>
            <div class="h-2 bg-primary/50 rounded col-span-1"></div>
          </div>
          <div class="h-2 bg-primary/50 rounded"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnimationComponent',
};
</script>

<style scoped>

</style>
