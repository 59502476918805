<script setup>
import { mdiNoteEditOutline } from '@mdi/js';
import { reactive, watch } from 'vue';
import useAdminStore from '@/store';
import IconModal from '@/components/admin/common/IconModal.vue';
import Chip from '@/components/admin/common/Chip.vue';
import {
  getBankTypeColor, getChipColor, getCountryColor, getIsActiveColor,
} from '@/utils/chipColor';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: () => {
    },
  },
  category: {
    type: String,
    required: true,
    default: '',
  },
  name: {
    type: String,
    required: true,
    default: '',
  },
});

const adminStore = useAdminStore();
const { t } = useI18n({});

const active = reactive({ is_active: props.data.is_active });

const state = reactive({
  isActive: false,
  icons: { mdiNoteEditOutline },
  colors: {
    isActiveColor: getIsActiveColor(active.is_active),
    categoryColor: getChipColor(props.category),
    countryColor: getCountryColor(props.data.country),
    accountColor: getBankTypeColor(props.data.account_name),
  },
  // eslint-disable-next-line no-nested-ternary
  category: props.category === t('common.tabNames.internetBanking') ? 'İnternet Bankacılığı'
    : t('common.tabNames.cryptoCurrencies') ? 'Kripto Paralar' : 'Swift',
});

watch(() => props.data.is_active, () => {
  active.is_active = props.data.is_active;
  state.colors.isActiveColor = getIsActiveColor(active.is_active);
});

function addBank() {
  // eslint-disable-next-line no-undef
  if (state.isActive) {
    adminStore.appendBank(props.data.id, state.category);
  } else {
    adminStore.removeBank(props.data.id, state.category);
  }
}
</script>

<template>
  <div class="flex flex-row">
    <div class="flex flex-row my-auto">
    <span class="text-lg mr-2">
      {{ name }}
    </span>
      <div class="flex flex-row space-x-2">
        <Chip
          :text="category"
          :color="state.colors.categoryColor"
        class="hidden sm:flex" />
        <Chip class="hidden sm:flex" v-if="props.data.country"
              :text="props.data.country"
              :color="state.colors.countryColor[0]">
          <template v-slot:flag>
            <img class="w-6 h-6 flex my-auto"
                 alt="CA Flag"
                 :src="state.colors.countryColor[1]"/>
          </template>
        </Chip>
        <Chip class="hidden sm:flex" v-if="props.data.currency"
              :text="props.data.currency"
              :color="state.colors.accountColor" />
        <Chip
          :text="props.data.is_active ? $t('admin.bankInformation.bankInformationInner.active')
          : $t('admin.bankInformation.bankInformationInner.passive')"
          :color="state.colors.isActiveColor" />
      </div>
    </div>
    <div class="flex ml-auto space-x-2">
      <label v-if="props.data.is_active" :for="props.data.id"
             class="bg-secondary cursor-pointer peer peer-checked:bg-white
              mt-0.5 relative w-10 h-5 rounded-full">
        <input type="checkbox" :id="props.data.id" class="sr-only peer"
               v-model="state.isActive" @change="addBank" :disabled="!data.is_active">
        <span class="w-2/5 h-4/5 bg-primary/50 absolute rounded-full left-1 top-0.5
         peer-checked:bg-primary peer-checked:left-5"></span>
      </label>
      <icon-modal
        class="my-auto"
        :category="props.category"
        :data="props.data"/>
    </div>
  </div>
</template>
