<script setup>
import useAdminStore from '@/store';
import { reactive } from 'vue';
import { createToast } from 'mosha-vue-toastify';

const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: () => {
    },
  },
});

const adminStore = useAdminStore();

const url = 'cryptocurrency';
const emit = defineEmits(['closeModal']);

const state = reactive({
  cryptoName: props.data.crypto_name,
  walletNumber: props.data.wallet_number,
  comment: props.data.comment,
  isActive: props.data.is_active,
  file: '',
});

async function save() {
  const formData = new FormData();
  formData.append('crypto_name', state.cryptoName);
  formData.append('wallet_number', state.walletNumber);
  formData.append('comment', state.comment);
  formData.append('is_active', state.isActive);
  formData.append('image_url', state.file);
  const isSend = await adminStore.updateBank(url, props.data.id, formData);
  if (isSend) {
    createToast(
      {
        title: 'Başarılı',
        description: 'Kripto bilgileri başarıyla güncellendi.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  } else {
    createToast(
      {
        title: 'Hata',
        description: 'Kripto bilgileri güncellenemedi, tekrar deneyiniz.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'danger',
        transition: 'slide',
      },
    );
  }
  setTimeout(() => { closeModal(); }, 1000);
}

async function deleteBank() {
  const isSend = await adminStore.deleteBank(url, props.data.id);
  if (isSend) {
    createToast(
      {
        title: 'Başarılı',
        description: 'Kripto bilgileri başarıyla silindi.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  } else {
    createToast(
      {
        title: 'Hata',
        description: 'Kripto bilgileri silinemedi, tekrar deneyiniz.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'danger',
        transition: 'slide',
      },
    );
  }
  setTimeout(() => { closeModal(); }, 1000);
}

function closeModal() {
  emit('closeModal', false);
}

async function handleFileUpload(e) {
  // eslint-disable-next-line prefer-destructuring
  state.file = e.target.files[0];
}
</script>

<template>
  <div>
  <div class="p-6 flex flex-col">
    <div class="flex">
      <span class="text-primary text-lg">
      Kripto Para Ayarlarını Düzenle
        </span>
      <button class="flex ml-auto text-primary" @click="closeModal">X</button>
    </div>
    <div class="mb-4 space-y-4">
      <label for="cryptoName">
        <span class="block text-primary">Kripto Adı</span>
        <input id="cryptoName" type="text"
               v-model="state.cryptoName"
               class="border border-primary rounded-lg w-full p-2">
      </label>
      <label for="walletNumber">
        <span class="block text-primary">Cüzdan Numarası</span>
        <input id="walletNumber" type="text"
               v-model="state.walletNumber"
               class="border border-primary rounded-lg w-full p-2">
      </label>
      <label for="comment">
        <span class="block text-primary">Açıklama</span>
        <input id="comment" type="text"
               v-model="state.comment"
               class="border border-primary rounded-lg w-full p-2">
      </label>
      <label for="isActive" class="flex items-center mb-2 cursor-pointer relative">
        <input type="checkbox" id="isActive" class="sr-only"
               v-model="state.isActive">
        <div class="toggle-bg bg-link/25 h-6 w-11 rounded-full"></div>
        <span class="ml-3">Kripto parayı aktif et</span>
      </label>
      <label for="img" class="block">
        <span class="sr-only">Logo Yükleyiniz</span>
        <input id="img" type="file" accept="image/jpeg,image/png"
               class="block w-full text-sm text-primary file:mr-4 file:py-2
        file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold
        file:bg-secondary/75 file:text-primary hover:file:bg-secondary" @change="handleFileUpload"/>
      </label>

    </div>
    <div class=" space-x-4 flex ml-auto">
      <button class="text-center bg-warning/30 rounded-lg p-2 text-warning px-6"
              @click="deleteBank">Sil
      </button>
      <button class="text-center bg-primary/30 rounded-lg p-2 text-primary px-4"
              @click="save">Kaydet
      </button>
    </div>
  </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.toggle-bg:after {
  content: '';
  @apply absolute top-0.5 left-0.5 bg-white border border-link/25 rounded-full h-5 w-5
  transition shadow-sm;
}

input:checked + .toggle-bg:after {
  transform: translateX(100%);
  @apply border-white;
}

input:checked + .toggle-bg {
  @apply bg-primary border-primary;
}
</style>
