<script setup>
import Card from '@/components/admin/common/Card.vue';
import InputBox from '@/components/admin/common/InputBox.vue';
import Switch from '@/components/admin/common/Switch.vue';
import Button from '@/components/admin/common/Button.vue';
import { reactive, watch } from 'vue';
import useAdminStore from '@/store';
import { mdiNoteEditOutline } from '@mdi/js';
import { useI18n } from 'vue-i18n';
import { createToast } from 'mosha-vue-toastify';

const adminStore = useAdminStore();
const { t } = useI18n({});

const state = reactive({
  switchActive: true,
  nameSurname: '',
  token: adminStore.getToken,
  icons: {
    mdiNoteEditOutline,
  },
});

const url = 'access_token';

watch(() => adminStore.getToken, () => {
  state.token = adminStore.getToken;
});

function changeSwitch(newValue) {
  state.switchActive = newValue;
}

function copy() {
  navigator.clipboard.writeText(`${t('common.linkUrl')}${adminStore.getToken}`).then(() => {
    createToast(
      {
        title: 'Başarılı',
        description: 'Link başarıyla kopyalandı!',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  });
}

async function generateUrl() {
  const isSend = await adminStore.generateToken(url, state.nameSurname);
  if (isSend) {
    state.token = adminStore.getToken;
    createToast(
      {
        title: 'Başarılı',
        description: 'Link başarıyla oluşturuldu!',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  } else {
    createToast(
      {
        title: 'Hata',
        description: 'Link oluşturulamadı!',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'danger',
        transition: 'slide',
      },
    );
  }
}
</script>

<template>
  <div>
  <div class="flex justify-center pt-5">
    <Card :title="$t('admin.createLink.title')">
      <template v-slot:inputs>
        <div v-if="adminStore.getToken !== ''" class="flex">
          <span class="w-full">
          {{ $t('admin.createLink.linkCreated') }}
            <a href="#" class="text-primary font-semibold" @click="copy">
             {{$t('common.linkUrl') + state.token }}
            </a>
          </span>
          <button @click="copy">
            <svg fill="#337ab7" width="24" height="24" class="flex ml-auto">
              <path :d="state.icons.mdiNoteEditOutline"></path>
            </svg>
          </button>
        </div>
        <InputBox
          aria-required="true"
          v-if="state.switchActive" v-model="state.nameSurname"
          class="w-full md:w-2/3" :placeholder="$t('admin.createLink.nameSurname')"
          type="text" id="nameSurname" :label="$t('admin.createLink.nameSurname')"/>
        <Switch
          :label="$t('admin.createLink.createLinkSwitch')"
          id="generateUrl" @switchChange="changeSwitch"/>
      </template>
      <template v-slot:buttons>
        <Button
          :button-text="$t('admin.createLink.createLink')"
          @click="generateUrl"/>
      </template>
    </Card>
  </div>
  </div>
</template>
