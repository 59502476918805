<template>
  <div class="h-screen bg-background w-screen md:p-10">
    <Suspense>
      <component :is="tab" />
    </Suspense>
  </div>
</template>

<script>
import Web from '@/components/dashboard/Web.vue';
import Mobile from '@/components/dashboard/Mobile.vue';

export default {
  name: 'DashBoard',
  components: {
    Web,
    Mobile,
  },

  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  computed: {
    tab() {
      return this.windowWidth >= 768 ? 'Web' : 'Mobile';
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize);
    });
  },

  beforeUnmount() {
    window.removeEventListener('resize', this.onResize);
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },
};
</script>
