import { defineStore } from 'pinia';
import axios from 'axios';
import { useRouter } from 'vue-router';

const useLoginStore = defineStore('login', {

  state: () => ({
    api_url: 'https://payments.cmglobals.com/api/v1',
    isLoggedIn: false,
    token: sessionStorage.getItem('token'),
    loginEndpoint: '/users/login/',
  }),
  getters: {
    getToken: (state) => state.token,
    getLoginEndpoint: (state) => state.loginEndpoint,
  },
  actions: {

    async login(payload) {
      await axios.post(this.api_url + this.getLoginEndpoint, payload).then(
        (res) => {
          sessionStorage.setItem('token', res.data.token);
          this.token = res.data.token;
          this.isLoggedIn = true;
        },
      ).catch((error) => {
        if (error.response) {
          this.isLoggedIn = false;
        }
      });
      return this.isLoggedIn;
    },

    checkToken() {
      const router = useRouter();
      if (this.getToken) {
        this.isLoggedIn = true;
        router.push({ path: '/' });
      }
    },
  },
});

export default useLoginStore;
