<template>
  <div v-show="isActive"><slot class="overflow-y-scroll"></slot></div>
</template>

<script>
export default {
  name: 'TabComponent',
  props: {
    name: { required: true },
    selected: { default: false },
  },

  data() {
    return {
      isActive: false,
    };
  },

  mounted() {
    this.isActive = this.selected;
  },

  created() {
    this.$parent.tabs.push(this);
  },
};
</script>

<style scoped>

</style>
