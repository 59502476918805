<script setup>
import SwiftInformation from '@/components/dashboard/common/SwiftInformation.vue';
import useDashboardStore from '@/store/dashboard';
import { onMounted, reactive } from 'vue';

const dashBoardStore = useDashboardStore();

const state = reactive({
  activeBanks: [],
  passiveBanks: [],
  loading: true,
});

await onMounted(async () => {
  state.activeBanks = dashBoardStore.getSwiftActiveFilteredBanks;
  state.passiveBanks = dashBoardStore.getSwiftPassiveFilteredBanks;
  state.loading = false;
});
</script>

<template>
  <div>
    <div class="grid mb-6 w-full grid-cols-1 flex gap-2 lg:grid-cols-2 xl:grid-cols-3 mt-3 md:px-6">
      <div v-for="(banking, index) in state.activeBanks" :key="index" class="p-2">
        <SwiftInformation
          :swift="banking"></SwiftInformation>
      </div>
      <div v-for="(banking, index) in state.passiveBanks" :key="index" class="p-2">
        <SwiftInformation
          :swift="banking"></SwiftInformation>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwiftInformations',
};
</script>
