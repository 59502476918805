<script setup>
import InputBox from '@/components/admin/common/InputBox.vue';
import Button from '@/components/admin/common/Button.vue';
import { reactive } from 'vue';
import useAdminStore from '@/store';
import { createToast } from 'mosha-vue-toastify';

const adminStore = useAdminStore();

const url = 'swift';

const data = reactive({
  accountOwner: '',
  accountAddress: '',
  currency: '',
  bank: '',
  bankAddress: '',
  swiftCode: '',
  account: '',
  comment: '',
  file: File,
});

async function submitted() {
  const formData = new FormData();
  formData.append('account_owner', data.accountOwner);
  formData.append('account_address', data.accountAddress);
  formData.append('currency', data.currency);
  formData.append('bank', data.bank);
  formData.append('bank_address', data.bankAddress);
  formData.append('swift_code', data.swiftCode);
  formData.append('account', data.account);
  formData.append('comment', data.comment);
  formData.append('image_url', data.file);
  const isSend = await adminStore.addBank(url, formData);
  data.accountOwner = '';
  data.accountAddress = '';
  data.currency = '';
  data.bank = '';
  data.bankAddress = '';
  data.swiftCode = '';
  data.account = '';
  data.comment = '';
  data.file = File;
  if (isSend) {
    createToast(
      {
        title: 'Başarılı',
        description: 'Swift bilgileri başarıyla eklendi.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  } else {
    createToast(
      {
        title: 'Hata',
        description: 'Swift bilgileri eklenemedi, tekrar deneyiniz.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'danger',
        transition: 'slide',
      },
    );
  }
}

async function handleFileUpload(e) {
  // eslint-disable-next-line prefer-destructuring
  data.file = e.target.files[0];
}
</script>

<template>
  <form @submit.prevent="submitted" class="w-full">
    <InputBox
      v-model="data.accountOwner"
      placeholder="Alıcı Hesap Sahibi"
      type="text"
      label="Alıcı Hesap Sahibi"
      :id="data.accountOwner"
    ></InputBox>
    <InputBox
      placeholder="Alıcı Adres Bilgisi"
      type="text"
      label="Alıcı Adres Bilgisi"
      :id="data.accountAddress"
      v-model="data.accountAddress"
    ></InputBox>
    <InputBox
      placeholder="Para Birimi"
      type="text"
      label="Para Birimi"
      :id="data.currency"
      v-model="data.currency"
    ></InputBox>
    <InputBox
      placeholder="Alıcı Banka"
      type="text"
      label="Alıcı Banka"
      :id="data.bank"
      v-model="data.bank"
    ></InputBox>
    <InputBox
      placeholder="Alıcı Banka Adresi"
      type="text"
      label="Alıcı Banka Adresi"
      :id="data.bankAddress"
      v-model="data.bankAddress"
    ></InputBox>
    <InputBox
      placeholder="SWIFT Kodu"
      type="text"
      label="SWIFT Kodu"
      :id="data.swiftCode"
      v-model="data.swiftCode"
    ></InputBox>
    <InputBox
      placeholder="Alıcı Hesap"
      type="text"
      label="Alıcı Hesap"
      :id="data.account"
      v-model="data.account"
    ></InputBox>
    <InputBox
      placeholder="Açıklama"
      type="text"
      label="Açıklama"
      :id="data.comment"
      v-model="data.comment"
    ></InputBox>
    <label for="img" class="block">
      <span class="sr-only">Logo Yükleyiniz</span>
      <input id="img" type="file" accept="image/jpeg,image/png"
             class="block w-full text-sm text-primary file:mr-4 file:py-2
        file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold
        file:bg-secondary/75 file:text-primary hover:file:bg-secondary" @change="handleFileUpload"/>
    </label>

    <Button
      class="w-1/2 ml-auto"
      buttonText="Ekle"></Button>
  </form>
</template>
