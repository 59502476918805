<script setup>
import { reactive } from 'vue';
import useLoginStore from '@/store/login';
import { useRouter } from 'vue-router';
import { createToast } from 'mosha-vue-toastify';

const router = useRouter();
const loginStore = useLoginStore();
loginStore.checkToken();

const state = reactive({ username: '', password: '' });

async function login() {
  const payload = {
    username: state.username,
    password: state.password,
  };
  const isLoggedIn = await loginStore.login(payload);
  if (isLoggedIn) {
    router.push({ name: 'admin' });
  } else {
    state.password = '';
    state.username = '';
    createToast(
      {
        title: 'Giriş Yapılamadı!',
        description: 'Kullanıcı adı ya da şifre hatalı.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'danger',
        transition: 'slide',
      },
    );
  }
}
</script>

<template>
  <form class="bg-background flex flex-col items-center justify-center h-screen"
        @submit.prevent="login">
    <div class="border border-primary rounded-lg xl:w-1/2 md:w-2/3
     h-1/2 flex justify-center items-center flex-col">
    <div class="text-2xl text-primary mb-10">Giriş Yap</div>
      <div class="mb-6 w-3/4">
      <label for="email" class="block mb-3 text-primary">
        Kullanıcı Adı
        <input type="text" class="bg-white border border-secondary text-link rounded-lg
        w-full p-2.5 focus:outline-none focus:ring-sky-500
        focus:ring-1" placeholder="Kullanıcı Adı" required v-model="state.username">
      </label>
    </div>
    <div class="mb-6 w-3/4">
      <label for="password" class="block mb-2 text-primary">
        Şifre
        <input type="password" class="bg-white border border-secondary text-link rounded-lg w-full
        p-2.5 focus:outline-none focus:ring-sky-500 focus:ring-1" placeholder="********" required
               v-model="state.password">
      </label>
    </div>
    <button type="submit" class="text-primary bg-secondary/75 w-3/4 hover:bg-secondary/100
    hover:shadow rounded-lg px-5 py-2.5 text-center">
      Giriş Yap</button>
    </div>
  </form>
</template>

<script>
export default {
  name: 'LoginView',
};
</script>
