<script>
import InputBox from '@/components/admin/common/InputBox.vue';
import AddBankInformation
  from '@/components/admin/bank-informations/modals/add-bank/AddBankInformation.vue';
import AddCryptoCurrency
  from '@/components/admin/bank-informations/modals/add-bank/AddCryptoCurrency.vue';
import AddSwift from '@/components/admin/bank-informations/modals/add-bank/AddSwift.vue';

export default {
  name: 'AddBank',
  components: {
    InputBox,
    AddBankInformation,
    AddCryptoCurrency,
    AddSwift,
  },

  data() {
    return {
      openTab: 1,
      isEdit: true,
      tabs: ['AddBankInformation', 'AddCryptoCurrency', 'AddSwift'],
      currentTab: 'AddBankInformation',
    };
  },
  methods: {
    toggleTabs(tabNumber) {
      this.openTab = tabNumber;
      this.currentTab = this.tabs[tabNumber - 1];
    },
    closeModal() {
      this.$emit('closeModal', false);
    },
  },
};
</script>

<script setup>
const emit = defineEmits(['closeModal']);

function closeModal() {
  emit('closeModal', false);
}
</script>

<template>
  <div class="flex flex-wrap bg-background w-full">
    <div class="w-full">
      <ul class="flex mb-0 list-none flex-wrap pt-3 pb-4 flex-row mx-3">
        <li class="mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 rounded block leading-normal"
             v-on:click="toggleTabs(1)" v-bind:class="{'text-primary bg-secondary': openTab
             !== 1, 'text-secondary bg-primary': openTab === 1}"
             @keydown.enter="toggleTabs(1)">
            Banka Bilgileri
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 rounded block leading-normal"
             v-on:click="toggleTabs(2)" v-bind:class="{'text-primary bg-secondary': openTab
             !== 2, 'text-secondary bg-primary': openTab === 2}"
             @keydown.enter="toggleTabs(2)">
            Kripto
          </a>
        </li>
        <li class="-mb-px mr-2 last:mr-0 flex-auto text-center">
          <a class="text-xs font-bold uppercase px-5 py-3 rounded block leading-normal"
             v-on:click="toggleTabs(3)" v-bind:class="{'text-primary bg-secondary':
             openTab !== 3, 'text-secondary bg-primary': openTab === 3}"
             @keydown.enter="toggleTabs(3)">
            Swift
          </a>
        </li>
        <button class="flex my-auto pl-3 text-primary" @click="closeModal">X</button>
      </ul>
      <div class="relative flex flex-col min-w-0 break-words mx-3 bg-white mb-6
      rounded-lg">
        <div class="px-4 py-5 flex-auto w-full">
          <component :is="currentTab"/>
        </div>
      </div>
    </div>
  </div>
</template>
