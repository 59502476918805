<script setup>
import InputBox from '@/components/admin/common/InputBox.vue';
import Button from '@/components/admin/common/Button.vue';
import { reactive } from 'vue';
import useAdminStore from '@/store';
import { createToast } from 'mosha-vue-toastify';

const adminStore = useAdminStore();

const url = 'internet_banking';

const data = reactive({
  bankName: '',
  accountName: '',
  branchCode: '',
  accountNumber: '',
  iban: '',
  currency: '',
  comment: '',
  countryTag: 'TR',
  file: File,
});

async function submitted() {
  const formData = new FormData();
  formData.append('country', data.countryTag);
  formData.append('bank_name', data.bankName);
  formData.append('account_name', data.accountName);
  formData.append('branch_code', data.branchCode);
  formData.append('account_number', data.accountNumber);
  formData.append('iban', data.iban);
  formData.append('currency', data.currency);
  formData.append('comment', data.comment);
  formData.append('image_url', data.file);
  const isSend = await adminStore.addBank(url, formData);
  data.bankName = '';
  data.accountName = '';
  data.branchCode = '';
  data.accountNumber = '';
  data.iban = '';
  data.currency = '';
  data.comment = '';
  data.countryTag = 'TR';
  data.file = File;
  if (isSend) {
    createToast(
      {
        title: 'Başarılı',
        description: 'Banka bilgileri başarıyla eklendi.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  } else {
    createToast(
      {
        title: 'Hata',
        description: 'Banka bilgileri eklenemedi, tekrar deneyiniz.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'danger',
        transition: 'slide',
      },
    );
  }
}

async function handleFileUpload(e) {
  // eslint-disable-next-line prefer-destructuring
  data.file = e.target.files[0];
}
</script>

<template>
  <form @submit.prevent="submitted" class="w-full">
    <label for="lang">
      <span class="text-primary text-base font-semibold">Ülke bilgisi: </span>
      <select name="languages" id="lang" v-model="data.countryTag" required>
        <option value="TR" selected>TUR</option>
        <option value="AUS">AUS</option>
        <option value="CDN">CAD</option>
        <option value="SGP">SGP</option>
      </select>
    </label>
    <InputBox
      v-model="data.bankName"
      placeholder="Banka Adı"
      type="text"
      label="Banka Adı"
      :id="data.bankName"
    ></InputBox>
    <InputBox
      placeholder="Hesap Adı"
      type="text"
      label="Hesap Adı"
      :id="data.accountName"
      v-model="data.accountName"
    ></InputBox>
    <InputBox
      placeholder="Şube Kodu"
      type="text"
      label="Şube Kodu"
      :id="data.branchCode"
      v-model="data.branchCode"
    ></InputBox>
    <InputBox
      placeholder="Hesap Numarası"
      type="text"
      label="Hesap Numarası"
      :id="data.accountNumber"
      v-model="data.accountNumber"
    ></InputBox>
    <InputBox
      placeholder="Iban"
      type="text"
      label="Iban"
      :id="data.iban"
      v-model="data.iban"
    ></InputBox>
    <InputBox
      placeholder="Döviz Birimi"
      type="text"
      label="Döviz Birimi"
      :id="data.currency"
      v-model="data.currency"
    ></InputBox>
    <InputBox
      placeholder="Açıklama"
      type="text"
      label="Açıklama"
      :id="data.comment"
      v-model="data.comment"
    ></InputBox>
    <label for="img" class="block">
      <span class="sr-only">Logo Yükleyiniz</span>
      <input id="img" type="file" accept="image/jpeg,image/png" required
             class="block w-full text-sm text-primary file:mr-4 file:py-2
        file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold
        file:bg-secondary/75 file:text-primary hover:file:bg-secondary" @change="handleFileUpload"/>
    </label>
    <Button
      class="w-1/2"
      buttonText="Ekle"></Button>
  </form>
</template>
