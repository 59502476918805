import { createApp } from 'vue';
import { createPinia } from 'pinia';
import QrcodeVue from 'qrcode.vue';
import { createI18n } from 'vue-i18n';
import tr from './plugins/i18n/locales/tr.json';
import en from './plugins/i18n/locales/en.json';
import App from './App.vue';
import router from './router';
import './style.css';

const i18n = createI18n({
  legacy: false,
  locale: 'tr',
  messages: {
    tr,
    en,
  },
});

const pinia = createPinia();

createApp(App).use(pinia).use(router)
  .use(i18n)
  .component('qrcode-vue', QrcodeVue)
  .mount('#app');
