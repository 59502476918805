<script setup>
import AddBank from '@/components/admin/bank-informations/modals/add-bank/AddBank.vue';
import { reactive } from 'vue';

const props = defineProps({
  buttonText: {
    type: String,
    default: '',
    required: true,
  },
});

const state = reactive({ showModal: false });

function toggleModal() {
  state.showModal = !state.showModal;
}
function closeToggle(value) {
  state.showModal = value;
}
</script>

<template>
  <div>
    <button class="w-fit px-5 py-2 bg-secondary text-primary font-medium
      text-sm rounded-lg transition duration-150 ease-in-out" @click="toggleModal">
      {{ props.buttonText }}
    </button>
    <div v-if="state.showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50
    outline-none justify-center items-center flex">
      <div class="bg-white w-2/3">
      <AddBank @closeModal="closeToggle" />
      </div>
    </div>
    <div v-if="state.showModal" class="opacity-25 fixed inset-0 z-40 bg-[#000000]"></div>
  </div>
</template>

<script>
export default { name: 'ModalComponent' };
</script>
