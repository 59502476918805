<script setup>
import Information from '@/components/dashboard/common/Information.vue';
import { onMounted, reactive } from 'vue';
import useDashboardStore from '@/store/dashboard';

const dashBoardStore = useDashboardStore();
const state = reactive({
  activeBanks: [],
  passiveBanks: [],
  filteredBanking: [],
  active: 1,
});

await onMounted(async () => {
  state.activeBanks = dashBoardStore.getIbActiveFilteredBanks;
  state.passiveBanks = dashBoardStore.getIbPassiveFilteredBanks;
  activate(1, 'TR');
});

function activate(chipNumber, countryTag) {
  state.filteredBanking = dashBoardStore.filterBanks(countryTag);
  state.active = chipNumber;
}

</script>

<template>
  <div>
    <div class="md:mx-8 mx-1 flex snap-x snap-mandatory space-x-2 flex-nowrap
    overflow-x-auto scrollbar-hide">
      <button :class="['px-2 rounded-lg flex flex-row', state.active === 1 ?
       'bg-primary text-secondary'
       : 'bg-secondary text-primary']" @click="activate(1, 'TR')">
        <img class="w-6 h-6 flex my-auto"
          alt="TR Flag"
          src="http://purecatamphetamine.github.io/country-flag-icons/3x2/TR.svg"/>
        <span class="flex ml-1 my-auto">TUR</span>
      </button>
      <button :class="['px-2 rounded-lg flex flex-row', state.active === 2 ?
      'bg-primary text-secondary'
       : 'bg-secondary text-primary']" @click="activate(2, 'AUS')">
        <img class="w-6 h-6 flex my-auto"
          alt="AUS Flag"
          src="http://purecatamphetamine.github.io/country-flag-icons/3x2/AU.svg"/>
        <span class="flex ml-1 my-auto">AUS</span>
      </button>
      <button :class="['p-2 rounded-lg flex flex-row', state.active === 3 ?
      'bg-primary text-secondary' : 'bg-secondary text-primary']" @click="activate(3, 'CDN')">
        <img class="w-6 h-6 flex my-auto"
          alt="CA Flag"
          src="http://purecatamphetamine.github.io/country-flag-icons/3x2/CA.svg"/>
        <span class="flex ml-1 my-auto">CAD</span>
      </button>
      <button :class="['px-2 rounded-lg flex flex-row', state.active === 4 ?
       'bg-primary text-secondary'
       : 'bg-secondary text-primary']" @click="activate(4, 'SGP')">
        <img class="w-6 h-6 flex my-auto"
          alt="SGP Flag"
          src="http://purecatamphetamine.github.io/country-flag-icons/3x2/SG.svg"/>
        <span class="flex ml-1 my-auto">SGP</span>
      </button>
    </div>
    <div v-if="state.active === 0"
         class="grid mb-6 w-full grid-cols-1 flex gap-2 lg:grid-cols-2
          xl:grid-cols-3 mt-3 md:px-6">
      <div v-for="(banking, index) in state.activeBanks" :key="index" class="p-2">
          <Information :internet-banking="banking"/>
      </div>
      <div v-for="(banking, index) in state.passiveBanks" :key="index" class="p-2">
          <Information :internet-banking="banking"/>
        </div>
    </div>
    <div class="grid mb-6 w-full grid-cols-1 flex gap-2 lg:grid-cols-2
          xl:grid-cols-3 mt-3 md:px-6" v-else>
      <div v-for="(banking, index) in state.filteredBanking" :key="index" class="p-2">
        <Information :internet-banking="banking" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InformationsComponent',
};
</script>
