<script setup>

import { reactive } from 'vue';
import { createToast } from 'mosha-vue-toastify';
import { mdiContentCopy } from '@mdi/js';

const props = defineProps({
  cryptoCurrency: {
    required: true,
  },
});

const state = reactive({
  size: 50,
  icons: {
    mdiContentCopy,
  },
});
function copyUrl() {
  navigator.clipboard.writeText(props.cryptoCurrency.wallet_number).then(() => {
    createToast(
      {
        title: 'Başarılı',
        description: 'Başarıyla kopyalandı!',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  });
}

</script>

<template>
  <div class="flex flex-row items-center w-full rounded-lg bg-secondary">
    <div class="flex-shrink-0 my-2">
      <img class="w-16 h-16 ml-2 rounded-full"
           :src="`https://payments-gate.com${props.cryptoCurrency.image_url}`" alt="Neil image">
    </div>
    <div class="flex flex-row w-full">
    <div class="flex flex-col  ml-4">
      <div class="mt-2 w-fit text-primary text-lg font-medium">
        {{ props.cryptoCurrency.crypto_name }}
      </div>
      <div class="text-link flex flex-col mb-2">
        <span class="font-medium my-auto flex">Cüzdan No: </span>
        <div class="flex flex-row ">
          <span class="my-auto text-xs break-all"> {{ props.cryptoCurrency.wallet_number }}</span>
          <button v-if="cryptoCurrency.wallet_number !== '**********'"
                  class="flex my-auto mx-4 " @click="copyUrl">
            <svg fill="#5D78A1" width="24" height="24">
              <path :d="state.icons.mdiContentCopy"></path>
            </svg>
          </button>
        </div>
      </div>
      <div class="text-link flex flex-col mb-2">
        <span class="font-medium my-auto flex">Açıklama: </span>
        <span class="my-auto break-all">
        {{
        props.cryptoCurrency.comment
        }}</span>
      </div>
    </div>
      <div v-if="props.cryptoCurrency.wallet_number !== '**********'"
           class="flex my-auto ml-auto mr-1">
      <qrcode-vue :value="cryptoCurrency.wallet_number"
                  foreground="#5D78A1" background="#E0EDFD"
                  :size=state.size level="H"/>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CryptoInformation',
};
</script>
