<script setup>
import Tab from '@/components/dashboard/web/Tab.vue';
import Tabs from '@/components/dashboard/web/Tabs.vue';
import Informations from '@/components/dashboard/common/Informations.vue';
import { onMounted, reactive } from 'vue';
import useDashboardStore from '@/store/dashboard';
import { useRoute, useRouter } from 'vue-router';
import CryptoInformations from '@/components/dashboard/common/CryptoInformations.vue';
import SwiftInformations from '@/components/dashboard/common/SwiftInformations.vue';
import Animation from '@/components/dashboard/common/Animation.vue';
import ChipAnimation from '@/components/dashboard/common/ChipAnimation.vue';

const dashBoardStore = useDashboardStore();

const router = useRouter();
const route = useRoute();
const name = reactive({ name: '' });
const loading = reactive({ isLoading: true });

onMounted(async () => {
  await dashBoardStore.getAllBanks();
  const isTokenPassive = await dashBoardStore.getActiveBanks(route.path);
  if (!isTokenPassive) {
    await router.push({ name: 'error' });
  } else {
    loading.isLoading = false;
    name.name = dashBoardStore.getUsername;
  }
});
</script>

<template>
  <div v-if="!loading.isLoading" class="h-full">
    <Tabs class="hidden md:flex"
          :name="name.name">
      <Tab :selected="true"
           :name="'İnternet Bankacılığı'">
        <Informations />
      </Tab>
      <Tab :name="'Kripto Paralar'">
        <CryptoInformations />
      </Tab>
      <Tab :name="'Swift'">
        <SwiftInformations />
      </Tab>
    </Tabs>
  </div>
  <div v-else class="h-full">
    <Tabs class="hidden md:flex"
          name="************">
      <Tab :selected="true"
           :name="'************'">
        <div class="flex flex-row ml-3">
          <div v-for="n in 5" class="my-2 mx-1" :key="n">
            <ChipAnimation/>
          </div>
        </div>
        <div v-for="n in 10" class="my-2 mx-4" :key="n">
          <Animation/>
        </div>
      </Tab>
      <Tab :name="'-----------'">
        <div v-for="n in 10" class="my-2 mx-4" :key="n">
          <Animation/>
        </div>
      </Tab>
      <Tab :name="'---------'">
        <div v-for="n in 10" class="my-2 mx-4" :key="n">
          <Animation/>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>

<script>
export default {
  name: 'WebView',
};
</script>
