<template>
  <div class="flex">
  <label for="teal-toggle"
         class="bg-secondary cursor-pointer mt-0.5 relative w-10 h-5 rounded-full">
    <input type="checkbox" v-model="checked"
           @change="switchChange()" id="teal-toggle" class="sr-only peer">
    <span class="w-2/5 h-4/5 bg-primary/50 absolute rounded-full left-1 top-0.5
         peer-checked:bg-primary peer-checked:left-5"></span>
  </label>
  <span class="ml-3 my-auto text-sm text-primary">{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: 'SwitchComponent',
  props: [
    'label', 'id',
  ],
  data() {
    return {
      checked: true,
    };
  },

  methods: {
    switchChange() {
      this.$emit('switchChange', this.checked);
    },
  },
};
</script>

<style scoped>

</style>
