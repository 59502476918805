<script setup>
import Informations from '@/components/dashboard/common/Informations.vue';
import CryptoInformations from '@/components/dashboard/common/CryptoInformations.vue';
import SwiftInformations from '@/components/dashboard/common/SwiftInformations.vue';

const props = defineProps({
  name: {
    required: true,
  },
});
</script>

<template>
  <div>
<div class="flex flex-col pl-4 bg-secondary h-fit w-full">
  <div class="flex flex-row">
  <div class="text-primary  text-lg mt-3">
    Hoşgeldiniz,
    <div class="font-semibold ">{{ props.name }}</div>
  </div>
    <!-- <img src="../../../assets/logo2.webp" alt="Logo" class="w-1/2 my-auto ml-auto h-1/2 "> -->
  </div>
  <div class="w-full">
    <ul class="space-x-2 flex overflow-x-auto border-primary pt-2 text-primary">
      <li class="flex-shrink-0">
        <button
          @click="currentTab(1)"
          :class="{'border-b border-primary pb-2': tab === 1}"
        >
          İnternet Bankacılığı
        </button>
      </li>
      <li class="flex-shrink-0">
        <button
          @click="currentTab(2)"
          class=" px-4"
          :class="{'border-b border-primary pb-2': tab === 2}"
        >
          Kripto Paralar
        </button>
      </li>
      <li class="flex-shrink-0">
        <button
          @click="currentTab(3)"
          class=" px-4"
          :class="{'border-b border-primary pb-2': tab === 3}"
        >
          Swift
        </button>
      </li>
    </ul>
  </div>
</div>
    <div class="mt-6 w-full">
      <div v-if="tab === 1">
        <Informations />
      </div>
      <div v-if="tab === 2">
        <CryptoInformations />
      </div>
      <div v-if="tab === 3">
        <SwiftInformations />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      tab: 1,
    };
  },
  methods: {
    currentTab(tabNumber) {
      this.tab = tabNumber;
    },
  },
};
</script>
