<script setup>
import { mdiContentCopy } from '@mdi/js';
import { reactive } from 'vue';
import { createToast } from 'mosha-vue-toastify';

const props = defineProps({
  swift: {
    required: true,
  },
});

const state = reactive({
  icons: {
    mdiContentCopy,
  },
});

function copyUrl() {
  navigator.clipboard.writeText(props.swift.comment).then(() => {
    createToast(
      {
        title: 'Başarılı',
        description: 'Link başarıyla kopyalandı!',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  });
}

</script>

<template>
  <div class="flex w-full flex-row items-center rounded-lg bg-secondary">
    <div class="flex-shrink-0">
      <img class="w-16 h-16 ml-2 rounded-full"
           :src="`https://payments-gate.com${props.swift.image_url}`" alt="Neil image">
    </div>
    <div class="flex w-full flex-col ml-4">
      <div class="mt-2 text-primary text-lg font-medium">{{ props.swift.account_owner }}</div>
      <div class="text-link">
        <span class="font-medium">Alıcı Adres Bilgisi:</span>
        {{
          props.swift.account_address
        }}
      </div>
      <div class="text-link">
        <span class="font-medium">Para Birimi:</span>
        {{
          props.swift.currency
        }}
      </div>
      <div class="text-link">
        <span class="font-medium">Alıcı Banka:</span>
        {{
          props.swift.bank
        }}
      </div>
      <div class="flex">
      <span class="w-48 text-ellipsis text-link overflow-hidden whitespace-nowrap">
      <span class="font-medium">Alıcı Banka Adresi:</span> {{
          props.swift.bank_address
        }}
      </span>
      </div>
      <div class="flex">
      <span class="w-48 text-ellipsis text-link overflow-hidden whitespace-nowrap">
      <span class="font-medium">SWİFT Kodu:</span> {{
          props.swift.swift_code
        }}
      </span>
      </div>
      <div class="flex">
      <span class="w-48 text-ellipsis text-link overflow-hidden whitespace-nowrap">
      <span class="font-medium">Alıcı Hesap:</span> {{
          props.swift.account
        }}
      </span>
      </div>
      <div class="text-link flex flex-row mb-2">
        <span class="font-medium">Açıklama:</span>
        {{
          props.swift.comment
        }}
        <button v-if="props.swift.comment !== '**********'"
                class="flex ml-auto mr-4" @click="copyUrl">
          <svg fill="#5D78A1" width="24" height="24">
            <path :d="state.icons.mdiContentCopy"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SwiftInformation',
};
</script>
