<script setup>
import useAdminStore from '@/store';
import { reactive } from 'vue';
import { createToast } from 'mosha-vue-toastify';

const props = defineProps({
  data: {
    type: Object,
    required: true,
    default: () => {
    },
  },
});

const adminStore = useAdminStore();

const url = 'internet_banking';
const emit = defineEmits(['closeModal']);

const state = reactive({
  countryTag: props.data.country,
  bankName: props.data.bank_name,
  accountName: props.data.account_name,
  branchCode: props.data.branch_code,
  accountNumber: props.data.account_number,
  iban: props.data.iban,
  comment: props.data.comment,
  currency: props.data.currency,
  isActive: props.data.is_active,
  file: '',
  modal: false,
});

async function save() {
  const formData = new FormData();
  formData.append('country', state.countryTag);
  formData.append('bank_name', state.bankName);
  formData.append('account_name', state.accountName);
  formData.append('branch_code', state.branchCode);
  formData.append('account_number', state.accountNumber);
  formData.append('iban', state.iban);
  formData.append('currency', state.currency);
  formData.append('comment', state.comment);
  formData.append('image_url', state.file);
  formData.append('is_active', state.isActive);
  const isSend = await adminStore.updateBank(url, props.data.id, formData);
  if (isSend) {
    createToast(
      {
        title: 'Başarılı',
        description: 'Banka bilgileri başarıyla güncellendi.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  } else {
    createToast(
      {
        title: 'Hata',
        description: 'Banka bilgileri güncellenemedi, tekrar deneyiniz.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'danger',
        transition: 'slide',
      },
    );
  }

  setTimeout(() => { closeModal(); }, 1000);
}

async function deleteBank() {
  const isSend = await adminStore.deleteBank(url, props.data.id);
  if (isSend) {
    createToast(
      {
        title: 'Başarılı',
        description: 'Banka bilgileri başarıyla silindi.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  } else {
    createToast(
      {
        title: 'Hata',
        description: 'Banka bilgileri silinemedi, tekrar deneyiniz.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'danger',
        transition: 'slide',
      },
    );
  }
  setTimeout(() => { closeModal(); }, 1000);
}

function closeModal() {
  emit('closeModal', false);
}

async function handleFileUpload(e) {
  // eslint-disable-next-line prefer-destructuring
  state.file = e.target.files[0];
}
</script>

<template>
  <div>
  <div class="p-6 flex flex-col">
    <div class="flex">
      <span class="text-primary text-lg">
      Banka Ayarlarını düzenle
        </span>
      <button class="flex ml-auto text-primary" @click="closeModal">X</button>
    </div>
    <div class="mb-4 space-y-4">
      <label for="lang">
        <span class="block text-primary">Ülke bilgisi: </span>
        <select name="languages"
                class="rounded-lg border text-primary focus:border focus:border-primary
                 border-primary"
                id="lang" v-model="state.countryTag">
          <option value="TR">TR</option>
          <option value="AUS">AUS</option>
          <option value="CDN">CDN</option>
          <option value="SGP">SGP</option>
        </select>
      </label>
      <label for="bankName">
        <span class="block text-primary">Banka Adı</span>
        <input id="bankName" type="text"
               v-model="state.bankName"
               class="border border-primary rounded-lg w-full p-2">
      </label>
      <label for="accountName">
        <span class="block text-primary">Hesap Adı</span>
        <input id="accountName" type="text"
               v-model="state.accountName"
               class="border border-primary rounded-lg w-full p-2">
      </label>
      <label for="branchCode">
        <span class="block text-primary">Şube Kodu</span>
        <input id="branchCode" type="text"
               v-model="state.branchCode"
               class="border border-primary rounded-lg w-full p-2">
      </label>
      <label for="accountNumber">
        <span class="block text-primary">Hesap Numarası</span>
        <input id="accountNumber" type="text"
               v-model="state.accountNumber"
               class="border border-primary rounded-lg w-full p-2">
      </label>
      <label for="iban">
        <span class="block text-primary">Iban</span>
        <input id="iban" type="text"
               v-model="state.iban"
               class="border border-primary rounded-lg w-full p-2">
      </label>
      <label for="currency">
        <span class="block text-primary">Döviz Birimi</span>
        <input id="currency" type="text"
               v-model="state.currency"
               class="border border-primary rounded-lg w-full p-2">
      </label>
      <label for="comment">
        <span class="block text-primary">Açıklama</span>
        <input id="comment" type="text"
               v-model="state.comment"
               class="border border-primary rounded-lg w-full p-2">
      </label>
      <label for="isActive" class="flex items-center mb-2 cursor-pointer relative">
        <input type="checkbox" id="isActive" class="sr-only"
               v-model="state.isActive">
        <div class="toggle-bg bg-link/25 h-6 w-11 rounded-full"></div>
         <span class="ml-3">Bankayı aktif et</span>
      </label>
      <label for="img" class="block">
        <span class="sr-only">Logo Yükleyiniz</span>
        <input id="img" type="file"
               class="block w-full text-sm text-primary file:mr-4 file:py-2
        file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold
        file:bg-secondary/75 file:text-primary hover:file:bg-secondary"
               accept="image/jpeg,image/png" @change="handleFileUpload"/>
      </label>
    </div>
    <div class=" space-x-4 flex ml-auto">
      <button class="text-center bg-warning/30 rounded-lg p-2 text-warning px-6"
              @click="deleteBank">Sil
      </button>
      <button class="text-center bg-primary/30 rounded-lg p-2 text-primary px-4"
              @click="save">Kaydet
      </button>
    </div>
  </div>
  </div>
</template>

<script>
export default {
};
</script>

<style scoped>
.toggle-bg:after {
  content: '';
  @apply absolute top-0.5 left-0.5 bg-white border border-link/25 rounded-full h-5 w-5
  transition shadow-sm;
}

input:checked + .toggle-bg:after {
  transform: translateX(100%);
  @apply border-white;
}

input:checked + .toggle-bg {
  @apply bg-primary border-primary;
}
</style>
