<script setup>
import Navbar from '@/components/dashboard/mobile/Navbar.vue';
import Animation from '@/components/dashboard/common/Animation.vue';
import { onMounted, reactive } from 'vue';
import useDashboardStore from '@/store/dashboard';
import { useRoute, useRouter } from 'vue-router';

const dashBoardStore = useDashboardStore();
const router = useRouter();
const route = useRoute();
const name = reactive({ name: '' });
const loading = reactive({ loading: true });

await onMounted(async () => {
  await dashBoardStore.getAllBanks();
  const isTokenPassive = await dashBoardStore.getActiveBanks(route.path);
  if (!isTokenPassive) {
    await router.push({ name: 'error' });
  } else {
    loading.loading = false;
    name.name = dashBoardStore.getUsername;
  }
});
</script>

<template>
<div  v-if="!loading.loading" class="flex flex-col h-fit">
  <Navbar :name="name.name"/>
  </div>
  <div v-else class="grid grid-cols-1">
    <div v-for="n in 12" class="m-4" :key="n">
      <Animation/>
    </div>
  </div>
</template>

<script>

export default {
  name: 'MobileView',
};
</script>
