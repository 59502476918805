<template>
  <div class="h-full flex w-full rounded-lg bg-white">
    <div class="bg-secondary w-2/6 lg:w-1/4 xl:w-1/6 rounded-l-lg flex
     flex-col items-center">
      <div class="flex my-auto flex-col space-y-10">
      <img src="../../../assets/logo2.webp" alt="Logo" class="w-3/4 mx-auto">
      <div class="space-y-10 w-full text-base lg:text-xl text-center">
      <div class="text-primary">Hoşgeldiniz,
        <div class="font-semibold ">{{ name }}</div>
      </div>
      <ul>
        <li v-for="tab in tabs" @click="selectTab(tab)" @keydown="selectedTab(tab)"
            :key="tab" :class="[
              'px-7 text-start rounded-l-lg py-5 text-sm lg:text-base  leading-5 text-blue-700',
              'ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 ' +
               'focus:outline-none focus:ring-2',
              tab.isActive
                ? 'bg-white border-l-4 text-primary border-primary '
                : 'text-link hover:font-semibold',
            ]">
          <a href="#" >{{ tab.name }}</a>
        </li>
      </ul>
      </div>

      </div>
    </div>
    <div class="w-4/6 py-5 lg:w-3/4 xl:w-5/6">
      <div class="flex h-full pb-5 flex-column">
        <div class="overflow-y-scroll w-full scrollbar-hide">
          <slot></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TabsComponent',
  data() {
    return { tabs: [], selectedTab: '' };
  },
  props: ['name'],

  methods: {
    selectTab(selectedTab) {
      this.tabs.forEach((tab) => {
        // eslint-disable-next-line no-param-reassign
        tab.isActive = (tab.name === selectedTab.name);
        if (tab.isActive) {
          this.selectedTab = tab.name;
        }
      });
    },
  },
};
</script>

<style scoped>

</style>
