// eslint-disable-next-line import/prefer-default-export
export const CATEGORYCOLORS = {
  internetBanking: '#256D85',
  cryptoCurrency: '#E38B29',
  swift: '#411530',
  active: '#66bb6a',
  notActive: '#f44336',
  TR: '#C8102E',
  AUS: '#EF3340',
  CDN: '#D80621',
  SGP: '#C73b3C',
  TL: '#B7211C',
  USD: '#8B8230',
  OTHER: '#5F8780',
};
