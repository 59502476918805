<script setup>
import Admin from '@/components/admin/admin.vue';
import { defineComponent } from 'vue';

defineComponent({
  name: 'AdminView',
});

</script>

<template>
  <div class="min-h-screen bg-background w-screen">
    <Suspense>
      <Admin />
    </Suspense>
    </div>
</template>
