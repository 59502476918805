<script setup>
import BankInformations from '@/components/admin/bank-informations/BankInformations.vue';
import CreateLink from '@/components/admin/create-link/CreateLink.vue';
import {
  defineComponent, onMounted, reactive, watch,
} from 'vue';
import useAdminStore from '@/store';
import Animation from '@/components/dashboard/common/Animation.vue';

defineComponent({
  name: 'AsyncAdmin',
});

const admin = useAdminStore();

const bankInfo = reactive({ active: false });
const banks = reactive({ internetBanking: [], swift: [], cryptoCurrency: [] });
const loading = reactive({ isLoading: true });

await onMounted(async () => {
  await admin.getBanks();
  bankInfo.active = admin.getAnyBankAdded;
  banks.internetBanking = admin.getInternetBanking;
  banks.swift = admin.getSwift;
  banks.cryptoCurrency = admin.getCryptoCurrency;
  loading.isLoading = false;
});

watch(() => admin.getInternetBanking, () => {
  banks.internetBanking = admin.getInternetBanking;
});
watch(() => admin.getSwift, () => {
  banks.swift = admin.getSwift;
});
watch(() => admin.getCryptoCurrency, () => {
  banks.cryptoCurrency = admin.getCryptoCurrency;
});

</script>

<template>
  <div v-if="!loading.isLoading" class="h-full">
    <CreateLink v-if="bankInfo.active"/>
    <BankInformations :internet-banking="banks.internetBanking"
                      :swift='banks.swift'
                      :crypto-currency="banks.cryptoCurrency"/>
  </div>
  <div v-else class="grid grid-cols-3">
    <div v-for="n in 12" class="m-4" :key="n">
      <Animation/>
    </div>
  </div>
</template>
