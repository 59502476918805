<template>
  <div class="rounded-lg bg-secondary/50 p-4 w-full mx-auto">
    <div class="animate-pulse flex space-x-4">
      <div class="space-y-6 py-1">
          <div class="grid grid-cols-3 gap-4">
            <div class="h-2 bg-primary/50 rounded col-span-2"></div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ChipAnimation',
};
</script>

<style scoped>

</style>
