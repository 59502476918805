<script setup>
import CryptoInformation from '@/components/dashboard/common/CryptoInformation.vue';
import useDashboardStore from '@/store/dashboard';
import { onMounted, reactive } from 'vue';
import Animation from '@/components/dashboard/common/Animation.vue';

const dashBoardStore = useDashboardStore();

const state = reactive({
  activeBanks: [],
  passiveBanks: [],
  loading: true,
});

await onMounted(async () => {
  state.activeBanks = dashBoardStore.getCryptoActiveFilteredBanks;
  state.passiveBanks = dashBoardStore.getCryptoPassiveFilteredBanks;
  state.loading = false;
});
</script>

<template>
  <div v-if="!state.loading">
    <div class="grid mb-6 w-full grid-cols-1 flex gap-2 lg:grid-cols-2 xl:grid-cols-3 mt-3 md:px-6">
      <div v-for="(banking, index) in state.activeBanks" :key="index" class="p-2">
        <CryptoInformation
          :crypto-currency="banking"></CryptoInformation>
      </div>
      <div v-for="(banking, index) in state.passiveBanks" :key="index" class="p-2">
        <CryptoInformation
          :crypto-currency="banking"></CryptoInformation>
      </div>
    </div>
  </div>
  <div v-else>
    <div v-for="n in 12" class="m-4" :key="n">
      <Animation/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CryptoInformations',
};
</script>
