<script setup>

defineProps({
  title: {
    type: String,
    required: true,
  },
});

</script>

<template>
  <div class="flex w-screen md:px-12 px-4">
    <div class="flex flex-col w-full space-y-2 p-6 rounded-lg bg-white">
      <div class="flex flex-row my-auto border-b border-link/20 mb-2">
        <h5 class="text-link text-lg my-auto font-medium">{{ title }}</h5>
        <div  class="ml-auto mb-2">
          <slot name="add-bank"></slot>
        </div>
      </div>
      <slot name="inputs"></slot>
      <div v-if="hasBottomButton" class="flex justify-end border-t
      border-link/20">
        <slot name="buttons"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CardComponent',
  computed: {
    hasBottomButton() {
      return !!this.$slots.buttons;
    },
  },
};
</script>
