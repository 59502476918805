import { CATEGORYCOLORS } from '@/categoryColors';

export function getChipColor(category) {
  if (category === 'İnternet Bankacılığı' || category === 'Internet Banking') {
    return CATEGORYCOLORS.internetBanking;
  } if (category === 'Kripto Paralar' || category === 'Crypto Currencies') {
    return CATEGORYCOLORS.cryptoCurrency;
  }
  return CATEGORYCOLORS.swift;
}

export function getIsActiveColor(isActive) {
  if (isActive === false) {
    return CATEGORYCOLORS.notActive;
  }
  return CATEGORYCOLORS.active;
}

export function getCountryColor(countryTag) {
  if (countryTag === 'TR') {
    return [CATEGORYCOLORS.TR, 'http://purecatamphetamine.github.io/country-flag-icons/3x2/TR.svg'];
  } if (countryTag === 'AUS') {
    return [CATEGORYCOLORS.AUS, 'http://purecatamphetamine.github.io/country-flag-icons/3x2/AU.svg'];
  } if (countryTag === 'CDN') {
    return [CATEGORYCOLORS.CDN, 'http://purecatamphetamine.github.io/country-flag-icons/3x2/CA.svg'];
  }
  return [CATEGORYCOLORS.SGP, 'http://purecatamphetamine.github.io/country-flag-icons/3x2/SG.svg'];
}

export function getBankTypeColor(accountName) {
  if (accountName === 'TL') {
    return CATEGORYCOLORS.TL;
  }
  if (accountName === 'USD') {
    return CATEGORYCOLORS.USD;
  }
  return CATEGORYCOLORS.OTHER;
}
