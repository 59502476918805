<script setup>
import InputBox from '@/components/admin/common/InputBox.vue';
import Button from '@/components/admin/common/Button.vue';
import { reactive } from 'vue';
import useAdminStore from '@/store';
import { createToast } from 'mosha-vue-toastify';

const adminStore = useAdminStore();

const url = 'cryptocurrency';

const data = reactive({
  cryptoName: '', walletNumber: '', comment: '', file: File,
});

async function submitted() {
  const formData = new FormData();
  formData.append('crypto_name', data.cryptoName);
  formData.append('wallet_number', data.walletNumber);
  formData.append('comment', data.comment);
  formData.append('image_url', data.file);
  const isSend = await adminStore.addBank(url, formData);
  data.cryptoName = '';
  data.walletNumber = '';
  data.comment = '';
  data.file = File;
  if (isSend) {
    createToast(
      {
        title: 'Başarılı',
        description: 'Kripto bilgileri başarıyla eklendi.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'success',
        transition: 'slide',
      },
    );
  } else {
    createToast(
      {
        title: 'Hata',
        description: 'Kripto bilgileri eklenemedi, tekrar deneyiniz.',
      },
      {
        showIcon: 'true',
        position: 'top-right',
        type: 'danger',
        transition: 'slide',
      },
    );
  }
}

async function handleFileUpload(e) {
  // eslint-disable-next-line prefer-destructuring
  data.file = e.target.files[0];
}
</script>

<template>
  <form @submit.prevent="submitted" class="w-full">
    <InputBox
      v-model="data.cryptoName"
      placeholder="Kripto Adı"
      type="text"
      label="Kripto Adı"
      :id="data.cryptoName"
    ></InputBox>
    <InputBox
      v-model="data.walletNumber"
      placeholder="Cüzdan Numarası"
      type="text"
      label="Cüzdan Numarası"
      :id="data.walletNumber"
    ></InputBox>
    <InputBox
      v-model="data.comment"
      placeholder="Açıklama"
      type="text"
      label="Açıklama"
      :id="data.comment"
    ></InputBox>
    <label for="img" class="block">
      <span class="sr-only">Logo Yükleyiniz</span>
      <input id="img" type="file" accept="image/jpeg,image/png"
             class="block w-full text-sm text-primary file:mr-4 file:py-2
        file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold
        file:bg-secondary/75 file:text-primary hover:file:bg-secondary" @change="handleFileUpload"/>
    </label>

    <Button
      class="w-1/2"
      buttonText="Ekle"></Button>
  </form>
</template>
