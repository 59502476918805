<script setup>
import { ref } from 'vue';

defineProps(['placeholder', 'modelValue', 'type', 'id', 'label']);
const input = ref('');

</script>

<template>
  <div class="w-full">
      <label :for="id" class="block flex flex-col mb-2 text-primary"
      >{{ label }}
        <input
          :type="type"
          class="
        form-control
        px-3
        py-1.5
        text-primary
        border border-primary/75
        rounded-lg
        transition
        ease-in-out
        focus:border-blue-600 focus:outline-none
      "
          v-model="input"
          @input="$emit('update:modelValue', $event.target.value)"
          :id="id"
          :placeholder="placeholder"
          required
        /></label
      >
  </div>
</template>
